import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import {BehaviorSubject, filter, Observable, switchMap, throwError} from 'rxjs'; // Import throwError
import {catchError, take} from 'rxjs/operators';
import {v4 as uuid} from 'uuid';
import {AuthService} from './auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  private refreshTokenSubject = new BehaviorSubject<any>(null);
  private isRefreshing: boolean = false;

  constructor(private authService: AuthService) {
  }

  intercept(
      req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.token;
    req = this.enhanceRequest(req, token);

    if (req.url.includes('/api/v2/auth/refresh')) {
      return next.handle(req);
    }

    return next.handle(req).pipe(
        catchError((error) => {
          const is401 = error instanceof HttpErrorResponse && error.status ===
              401;

          if (is401 && error.error?.msg === 'Token has expired') {
            return this.handle401Error(req, next);
          } else if (is401) {
            this.authService.logOutOnError('Authentication failed!');
            return throwError(() => error);
          } else {
            return throwError(() => error);
          }
        }),
    );
  }

  private enhanceRequest(req: HttpRequest<any>, token: string) {
    const update: any = {setHeaders: {'X-Request-ID': uuid()}};
    if (token) {
      update.setHeaders.Authorization = `Bearer ${token}`;
    }
    return req.clone(update);
  }

  private handle401Error(request: any, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refresh().pipe(
          switchMap((response: any) => {
            this.isRefreshing = false;

            this.authService.token = response.token;
            this.authService.refreshToken = response.refresh_token;

            this.refreshTokenSubject.next(response.token);
            return next.handle(this.enhanceRequest(request, response.token));
          }),
          catchError((refreshErr: any) => {
                this.authService.logOutOnError('Your refresh token has expired. Log in again');
                return throwError(() => refreshErr);
              },
          ));
    } else {
      return this.refreshTokenSubject.pipe(
          filter((token) => token != null),
          take(1),
          switchMap((token) => {
            return next.handle(this.enhanceRequest(request, token));
          }),
      );
    }
  }
}
