import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {StatusFilterPipe} from './status-filter.pipe';
import {TextTransformPipe} from './text-transform.pipe';
import {PhonePipe} from './phone.pipe';
import {PipelineFilterPipe} from './pipeline-filter.pipe';
import {UserIdFilterPipe} from './user-id-filter.pipe';
import {UtcToLocalDateTimePipe} from './utc-to-local-date-time.pipe';
import {UtcToLocalDatePipe} from './utc-to-local-date.pipe';
import {NaiveDatePipe} from './naive-date.pipe';
import {UtcToLocalTimePipe} from './utc-to-local-time.pipe';
import {CustomFilterPipe} from './custom-filter.pipe';

@NgModule({
  declarations: [
    StatusFilterPipe,
    TextTransformPipe,
    PhonePipe,
    PipelineFilterPipe,
    UserIdFilterPipe,
    UtcToLocalDateTimePipe,
    UtcToLocalDatePipe,
    UtcToLocalTimePipe,
    NaiveDatePipe,
    CustomFilterPipe,
  ],
  imports: [CommonModule],
  exports: [
    StatusFilterPipe,
    TextTransformPipe,
    PhonePipe,
    PipelineFilterPipe,
    UserIdFilterPipe,
    UtcToLocalDateTimePipe,
    UtcToLocalDatePipe,
    UtcToLocalTimePipe,
    NaiveDatePipe,
    CustomFilterPipe,
  ],
})
export class PipeModule {
}
