export const environment = {
  production: true,

  label: 'staging',
  DATE_FORMAT: 'dd MMM yyyy',
  API_URL: 'https://devapi.bridgersystem.com/api/v2',
  FEATURE_GOOGLE_LOGIN: true,
  FEATURE_MICROSOFT_LOGIN: true,
  FEATURE_SIGNATURE_IMAGE: false,
  FEATURE_BLUE: true,
  MAINTENANCE_MODE: false,
};
