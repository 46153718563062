export const STATUS = {
  ON: 'On',
  OFF: 'Off',
  ENABLED: 'On',
  DISABLED: 'Off',
  TEMPORARY: 't',
  PERMANENT: 'p',
};

export const PROVIDER = {
  GOOGLE: 'gmail',
  GMAIL: 'gmail',
  IMAP: 'imap',
  OUTLOOK: 'outlook',
  O365: 'o365',
};

export const FOLDERS : any = {
  INBOX: '\\Inbox',
  DRAFTS: '\\Drafts',
  ARCHIVE: '\\Archive',
  JUNK: '\\Junk',
  SENT: '\\Sent',
  TRASH: '\\Trash',
  IMPORTANT: '\\Important',
};

export const FOLDER_ATTR_ICONS : any = {
  '\\Inbox': 'fa-inbox',
  '\\Drafts': 'fa-file',
  '\\Important': 'fa-circle-exclamation',
  '\\Archive': 'fa-box-archive',
  '\\Sent': 'fa-paper-plane',
  '\\Trash': 'fa-trash-can',
  '\\Junk': 'fa-ban'
};

export const FOLDER_NAME_ICONS : any = {
  'Outbox': 'fa-right-from-bracket',
};

export const EARLIEST_MEETING_TIME: string = '00:00';
export const DEFAULT_MEETING_TIME: string = '09:00';

export const TO_INTERNAL: string = 'i';
export const TO_EXTERNAL: string = 'e';
export const WHOM_TO_SELECT = [
  {value: TO_INTERNAL, viewValue: 'Internal'},
  {value: TO_EXTERNAL, viewValue: 'External'},
];

// ---------------- CONTACT HELPERS
export const CONTACT = {
  NEW: 'n',
  SEQUENCE: 's',
  SEQUENCE_COMPLETE: 'q',
  CONTACT: 'c',
  MEETING: 'm',
  OPT_OUT: 'o',
  REPAIR: 'x',
  ARCHIVE: 'z',
};

export const CONTACT_TRANSITIONS = {
  n: 'cmoxz',
  s: '',
  q: 'nscmoxz',
  c: 'moxz',
  m: 'oxz',
  o: '',
  x: 'noz',
  z: 'n'
}

export const CONTACT_LABEL: any = {
  n: 'New',
  m: 'Meeting',
  s: 'In Sequence',
  q: 'Sequence Completed',
  c: 'Connected',
  o: 'Opt-Out',
  x: 'Repair',
  z: 'Archived'
};

export const CONTACT_ICON: any = {
  n: 'fa-solid fa-hourglass',
  m: 'fa-solid fa-regular fa-calendar-check',
  s: 'fa-solid fa-route',
  q: 'fa-solid fa-route',
  c: 'fa-solid fa-link',
  o: 'fa-solid fa-ban',
  x: 'fa-solid fa-screwdriver-wrench',
  z: 'fa-regular fa-snowflake',
};

export function expandContact(status: string): string {
  // @ts-ignore
  return CONTACT_LABEL[status] || 'Unknown';
}

export function contactSelect(values: string[] = []): any {
  return values.map((v: string) => ( {value: v, viewValue: expandContact(v)} ));
}

// ---------------- PIPELINE HELPERS

export const PIPELINE = {
  LEAD: 'le',
  QUOTED: 'qt',
  QUALIFIED: 'qu',
  CUSTOMER: 'cu',
};

export const PIPELINE_LABEL = {
  le: 'Lead',
  qt: 'Quoted',
  qu: 'Qualified',
  cu: 'Customer',
};

export function expandPipeline(status: string): string {
  // @ts-ignore
  return PIPELINE_LABEL[status] || 'Unknown';
}

export function pipelineSelect(values: string[]): any {
  return values.map(
      (v: string) => ( {value: v, viewValue: expandPipeline(v)} ));
}

export function pipelineBgClass(status: string): string {
  switch (status) {
    case PIPELINE.LEAD:
      return 'red-100';
    case PIPELINE.CUSTOMER:
      return 'teal-100';
    case PIPELINE.QUALIFIED:
      return 'orange-100';
    case PIPELINE.QUOTED:
      return 'yellow-100';
    default:
      return 'red-100';
  }
}

// ---------------- PRIORITY HELPERS

export const PRIORITY = {
  HIGH: 'h',
  MEDIUM: 'm',
  LOW: 'l',
};

export function priorityClassMuted(priority: string) {
  return {h: 'red-100', m: 'orange-100'}[priority] || 'teal-100';
}

export function priorityClass(priority: string): string {
  return {h: 'bg-danger', m: 'bg-warning'}[priority] || 'bg-info';
}

export function expandPriority(priority: string): string {
  return {h: 'High', m: 'Medium'}[priority] || 'Low';
}

// ---------------- SEQUENCE HELPERS

export const SEQUENCE = {
  RUNNING: 'r',
  NOT_ACTIVATED: 'n',
  COMPLETED: 'c',
  HOLD_TASK: 't',
  HOLD_EMAIL: 'h',
  STOP_TEMPLATE: 'e',
  STOP_REPLY: 's',
};

export const SEQUENCE_LABEL = {
  r: 'Running',
  n: 'Not Activated',
  c: 'Completed',
  t: 'On Hold (Task Due)',
  h: 'On Hold (Email Queue)',
  e: 'Stopped (Missing Template)',
  s: 'Stopped (Reply)',
};

export const SEQUENCE_SELECT = [
  {value: SEQUENCE.NOT_ACTIVATED, viewValue: SEQUENCE_LABEL.n},
  {value: SEQUENCE.RUNNING, viewValue: SEQUENCE_LABEL.r},
  {value: SEQUENCE.COMPLETED, viewValue: SEQUENCE_LABEL.c},
  {value: SEQUENCE.HOLD_TASK, viewValue: SEQUENCE_LABEL.t},
  {value: SEQUENCE.HOLD_EMAIL, viewValue: SEQUENCE_LABEL.h},
  {value: SEQUENCE.STOP_TEMPLATE, viewValue: SEQUENCE_LABEL.e},
  {value: SEQUENCE.STOP_REPLY, viewValue: SEQUENCE_LABEL.s},
];

export const SEQUENCE_TYPE = {
  AUDIO: 'audio',
  DIRECT_MESSAGE: 'dm',
  TEMPLATE_EMAIL: 't_email',
  MANUAL_EMAIL: 'm_email',
  MEETING: 'meeting',
  PHONE_CALL: 'call',
  VIDEO_MESSAGE: 'video',
  VIDEO_LINKED_IN: 'video_linkedin',
  OTHER: 'other',
};

export const SEQUENCE_TYPE_LABEL = {
  audio: 'Audio Message',
  dm: 'Direct Message',
  t_email: 'Template Email',
  m_email: 'Manual Email',
  meeting: 'Meeting',
  call: 'Phone Call',
  video: 'Video Message',
  video_linkedin: 'Video LinkedIn Message',
  other: 'Other',
};

export const SEQUENCE_TYPE_SHORT_LABEL = {
  audio: 'Audio',
  dm: 'DM',
  t_email: 'Template Email',
  m_email: 'Email',
  meeting: 'Meeting',
  call: 'Call',
  video: 'Video',
  video_linkedin: 'LI Video',
  other: 'Other',
};

export const SEQUENCE_THEME = {
  m_email: {icon: 'fa-envelope', label: 'Email'},
  call: {icon: 'fa-phone', label: 'Call'},
  dm: {icon: 'fa-comments', label: 'DM'},
  video: {icon: 'fa-file-video', label: 'Video'},
  video_linkedin: {icon: 'fa-file-video', label: 'LI Video'},
  audio: {icon: 'fa-volume-high', label: 'Audio'},
  other: {icon: 'fa-ellipsis', label: 'Other'},
  meeting: {icon: 'fa-handshake', label: 'Meeting'},
};

export const SEQUENCE_TYPE_SELECT = [
  {name: SEQUENCE_TYPE_LABEL.audio, value: SEQUENCE_TYPE.AUDIO},
  {name: SEQUENCE_TYPE_LABEL.dm, value: SEQUENCE_TYPE.DIRECT_MESSAGE},
  {name: SEQUENCE_TYPE_LABEL.t_email, value: SEQUENCE_TYPE.TEMPLATE_EMAIL},
  {name: SEQUENCE_TYPE_LABEL.m_email, value: SEQUENCE_TYPE.MANUAL_EMAIL},
  {name: SEQUENCE_TYPE_LABEL.meeting, value: SEQUENCE_TYPE.MEETING},
  {name: SEQUENCE_TYPE_LABEL.call, value: SEQUENCE_TYPE.PHONE_CALL},
  {name: SEQUENCE_TYPE_LABEL.video, value: SEQUENCE_TYPE.VIDEO_MESSAGE},
  {
    name: SEQUENCE_TYPE_LABEL.video_linkedin,
    value: SEQUENCE_TYPE.VIDEO_LINKED_IN,
  },
  {name: SEQUENCE_TYPE_LABEL.other, value: SEQUENCE_TYPE.OTHER},
];

export function expandSequenceStatus(seq: string) {
  // @ts-ignore
  return SEQUENCE_LABEL[seq] || 'UNKNOWN-' + seq;
}

export function sequenceTypeLabel(type: string) {
  // @ts-ignore
  return SEQUENCE_TYPE_LABEL[type] || type;
}

export function sequenceTypeShortLabel(type: string) {
  // @ts-ignore
  return SEQUENCE_TYPE_SHORT_LABEL[type] || type;
}

export function sequenceBgClass(status: string) {
  switch (status) {
    case SEQUENCE.RUNNING:
      return 'bg-success text-light';
    case SEQUENCE.HOLD_EMAIL:
      return 'bg-warning';
    case SEQUENCE.HOLD_TASK:
      return 'bg-warning';
    case SEQUENCE.NOT_ACTIVATED:
      return 'bg-warning';
    default:
      return 'bg-danger text-light';
  }
}

