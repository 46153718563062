// noinspection JSUnusedGlobalSymbols

import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {Observable, tap} from 'rxjs';
import {environment} from 'src/environments/environment';
import {ToastrService} from 'ngx-toastr';
import {STATUS} from '../services/globals';

export const ACTION = {
  canManageRoles: 'canManageRoles',
  canViewRoles: 'canViewRoles',
  canManageUsers: 'canManageUsers',
  canViewUsers: 'canViewUsers',
  canManageBranches: 'canManageBranches',
  canViewBranches: 'canViewBranches',
  canManageTeams: 'canManageTeams',
  canViewTeams: 'canViewTeams',
  canViewAllContacts: 'canViewAllContacts',
  canExportContacts: 'canExportContacts',
  canMoveContacts: 'canMoveContacts',
  canManage: 'canManage',
  canViewOps: 'canViewOps',
  canViewBridgerUsers: 'canViewBridgerUsers',
  canManageBridgerUsers: 'canManageBridgerUsers',
  canViewBridgerCompanies: 'canViewBridgerCompanies',
  canManageBridgerCompanies: 'canManageBridgerCompanies',
  canManageBridgerOutbox: 'canManageBridgerOutbox',
  canViewBridgerPackages: 'canViewBridgerPackages',
  canManageBridgerStripe: 'canManageBridgerStripe',
  canManageBridgerNylas: 'canManageBridgerNylas',
  canManageBridgerCF: 'canManageBridgerCF',
  canManageBridgerRelevance: 'canManageBridgerRelevance',
  canManageBridgerUplead: 'canManageBridgerUplead'
};

export const ADDON = {
  BLUE: 'blue',
  CHATGPT: 'chatGPT'
}

const url: any = environment.API_URL;
const routes = {
  auth: {
    postRf: () => `${url}/auth/register`,
    login: () => `${url}/auth/login`,
    googleAuth: () => `${url}/auth/googleAuth`,
    microsoftAuth: () => `${url}/auth/microsoftAuth`,
    loginOA: () => `${url}/auth/loginOA`,
    requestOTP: () => `${url}/auth/requestOTP`,
    verifyOtp: () => `${url}/auth/verifyOtp`,
    resetPassword: () => `${url}/auth/resetPassword`, // authenticated routes
    permissions: () => `${url}/auth/permissions`, // dashboard??

    sendGoogleToken: () => `${url}/dashboard/googleToken`,
    sendMicrosoftToken: () => `${url}/dashboard/microsoftToken`,
    sendNylasToken: () => `${url}/dashboard/nylasToken`,

    microsoftConnectionUrl: () => `${url}/dashboard/microsoftConnect`,
    googleConnectionUrl: () => `${url}/dashboard/googleConnect`,
    nylasConnectionUrl: () => `${url}/dashboard/nylasConnect`,

    refreshToken: () => `${url}/auth/refresh`,
  },
};

const COMPANY_ID = 'companyId';
const COMPANY_TYPE = 'companyType';
const DEFAULT_MAIL = 'mailer';
const PACKAGE = 'package';
const PERMISSIONS = 'permissions';
const PROFILE_PIC = 'profile_pic';
const REFRESH_TOKEN = 'refresh_token';
const TOKEN = 'token';
const TOKEN_EXP = 'tokenExp';
const USER_ID = 'userId';
const USER_NAME = 'userName';
const USER_EMAIL = 'userEmail';
const TAWK = 'tawkHash';
const ADDONS = 'addons';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private tokenExpirationDuration: any;

  constructor(private httpClient: HttpClient, public router: Router, private toaster: ToastrService) {
  }

  get companyId(): number {
    return Number(localStorage.getItem(COMPANY_ID));
  }

  set companyId(value: number) {
    localStorage.setItem(COMPANY_ID, String(value));
  }

  get companyType(): string {
    return localStorage.getItem(COMPANY_TYPE) || 'NONE';
  }

  set companyType(value: string) {
    localStorage.setItem(COMPANY_TYPE, value);
  }

  get isCompany() {
    return this.companyType === 'Company';
  }

  get isAgent() {
    return this.companyType === 'Agent';
  }

  get isManager() {
    return this.can(ACTION.canManage);
  }

  get companyPackage(): any {
    const EMPTY_PACKAGE = {
      trial: STATUS.DISABLED,
      status: STATUS.DISABLED,
      expiredAt: '2024-01-01T00:00:00.000Z',
      chatBot: STATUS.DISABLED,
      chatGPT: STATUS.DISABLED,
    };
    const pkg = localStorage.getItem(PACKAGE);
    return pkg ? JSON.parse(pkg) : EMPTY_PACKAGE;
  }

  set companyPackage(value: any) {
    localStorage.setItem(PACKAGE, JSON.stringify(value));
  }

  get chatBotEnabled(): boolean {
    return this.companyPackage.chatBot == STATUS.ENABLED;
  }

  get chatGPTEnabled(): boolean {
    return this.companyPackage.chatGPT == STATUS.ENABLED;
  }

  get mailer(): string {
    let m = localStorage.getItem(DEFAULT_MAIL);
    return m === 'null' ? '' : ( m || '' );
  }

  set mailer(value: string) {
    localStorage.setItem(DEFAULT_MAIL, value || '');
  }

  get token(): string {
    return localStorage.getItem(TOKEN) || '';
  }

  set token(value: string) {
    localStorage.setItem(TOKEN, value);
    this.tokenExp = this.getTokenExp(value);
  }

  get tokenExp(): number {
    return Number(localStorage.getItem(TOKEN_EXP));
  }

  private set tokenExp(value: number) {
    localStorage.setItem(TOKEN_EXP, String(value));
  }

  get refreshToken(): string {
    return localStorage.getItem(REFRESH_TOKEN) || '';
  }

  set refreshToken(value: string) {
    localStorage.setItem(REFRESH_TOKEN, value);
  }

  get addons(): string[] {
    return JSON.parse(localStorage.getItem(ADDONS) || '[]');
  }

  set addons(value: string[]) {
    localStorage.setItem(ADDONS, JSON.stringify(value));
  }

  get permissions(): string[] {
    return JSON.parse(localStorage.getItem(PERMISSIONS) || '[]');
  }

  set permissions(value: string[]) {
    value = value.filter((v: string) => v !== null);
    localStorage.setItem(PERMISSIONS, JSON.stringify(value));
  }

  get profilePicture(): string {
    let p = localStorage.getItem(PROFILE_PIC);
    return p && p !== 'null' ? p : '/assets/img/profile.svg';
  }

  set profilePicture(value: string) {
    localStorage.setItem(PROFILE_PIC, value);
  }

  get userName(): string {
    return localStorage.getItem(USER_NAME) || '';
  }

  set userName(value: string) {
    localStorage.setItem(USER_NAME, value);
  }

  get userId(): number {
    return Number(localStorage.getItem(USER_ID));
  }

  set userId(value: number) {
    localStorage.setItem(USER_ID, String(value));
  }

  get userEmail(): string {
    return localStorage.getItem(USER_EMAIL) || '';
  }

  set userEmail(value: string) {
    localStorage.setItem(USER_EMAIL, value);
  }

  get tawk(): string {
    return localStorage.getItem(TAWK) || '';
  }

  set tawk(value: string) {
    localStorage.setItem(TAWK, value);
  }

  can(permission: string) {
    return this.permissions.includes(permission);
  }

  hasAddon(addon: string) {
    return this.addons.includes(addon);
  }

  register(data: any): Observable<any> {
    return this.httpClient.post<any>(routes.auth.postRf(), data);
  }

  tick() {
    return this.httpClient.get<any>(`${url}/auth/tick`);
  }

  login(data: any) {
    data.meta = {
      sw: screen.width,
      sh: screen.height,
      wiw: window.innerWidth,
      wih: window.innerHeight,
      lang: window.navigator.language,
    };
    return this.httpClient.post<any>(routes.auth.login(), data, {withCredentials: true}).
        pipe(tap((res) => this.interceptLoginResponse(res)));
  }

  interceptLoginResponse(res: any) {
    if (res.status != 'success') return;
    this.clearStorage();

    const response = res.data;
    const user = response.user;
    // console.log(user);
    // console.log(response);

    this.companyId = user.companyId;
    this.companyType = user.companyType;
    this.companyPackage = user.companyPackage;

    this.mailer = user.defaultServer || '';
    this.permissions = response.permissions;
    this.userId = user.id;
    this.userEmail = user.email;
    this.setUserName(user.firstName, user.lastName);
    this.profilePicture = user.userProfilePicture;
    this.token = response.token;
    this.refreshToken = response.refresh_token;
    this.tawk = response.tawk;
    this.addons = response.addons || [];
  }

  getTokenExp(token: string) {
    try {
      return JSON.parse(atob(token.split('.')[1])).exp;
    } catch (e) {
      console.log(e);
      return 0;
    }
  }

  logOut() {
    this.clearStorage();
    this.router.navigate(['/login']);
  }

  clearStorage() {
    sessionStorage.clear();
    localStorage.clear();

    if (this.tokenExpirationDuration) {
      clearTimeout(this.tokenExpirationDuration);
    }
    this.tokenExpirationDuration = null;
  }

  requestOTP(data: any) {
    return this.httpClient.post<any>(routes.auth.requestOTP(), data);
  }

  verifyOTP(data: any) {
    return this.httpClient.post<any>(routes.auth.verifyOtp(), data);
  }

  resetPassword(data: any) {
    return this.httpClient.post<any>(routes.auth.resetPassword(), data);
  }

  getGoogleConnectionUrl() {
    return this.httpClient.get<any>(routes.auth.googleConnectionUrl());
  }

  getMicrosoftConnectionUrl() {
    return this.httpClient.get<any>(routes.auth.microsoftConnectionUrl());
  }

  getNylasConnectionUrl() {
    return this.httpClient.get<any>(routes.auth.nylasConnectionUrl());
  }

  getGoogleAuthUrl() {
    return this.httpClient.get<any>(routes.auth.googleAuth());
  }

  getMicrosoftAuthUrl() {
    return this.httpClient.get<any>(routes.auth.microsoftAuth());
  }

  loginOA(data: any) {
    return this.httpClient.post<any>(routes.auth.loginOA(), data, {withCredentials: true}).
        pipe(tap((res) => this.interceptLoginResponse(res)));
  }

  refreshPermissions() {
    this.httpClient.get<any>(routes.auth.permissions()).subscribe((res) => {
      this.permissions = res.permissions;
    });
  }

  sendGoogleToken(data: any) {
    return this.httpClient.post<any>(routes.auth.sendGoogleToken(), data);
  }

  sendMicrosoftToken(data: any) {
    return this.httpClient.post<any>(routes.auth.sendMicrosoftToken(), data);
  }

  sendNylasToken(data: any) {
    return this.httpClient.post<any>(routes.auth.sendNylasToken(), data);
  }

  refresh(): Observable<any> {
    const body = {refresh_token: this.refreshToken};
    return this.httpClient.post<any>(routes.auth.refreshToken(), body);
  }

  public logOutOnError(error: any) {
    this.toaster.error(`${error}`, 'Error Message');

    // Display the error message and then proceed with the rest of the code
    setTimeout(() => {
      this.router.navigate(['/login']);
      this.clearStorage();
    }, 100); // Use a timeout of 0 to ensure that the code runs after the error message is displayed
  }

  valid() {
    return this.userId > 0;
  }

  public setUserName(firstName: string, lastName: string) {
    this.userName = ( firstName + ' ' + lastName ).trim();
  }

}
